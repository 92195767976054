<template>
  <div class="px-2 mt-1">

		<b-nav card-header pills class="m-0">
			<b-nav-item
				v-for="(item, index) in tabs"
				:key="index"
				:to="{ name: item.route }"
				exact
				exact-active-class="active"
				:link-classes="['px-3', bgTabsNavs]"
				:id="'ab_'+index"
			>
				{{ item.title }}
			</b-nav-item>
		</b-nav>
		
		<router-view />

		<!--<b-tabs
			active-tab-class="p-0 "
			pills
			nav-class="mb-0"
			active-nav-item-class="bg-info box-shadow-info border-info info"
		>
			<b-tab lazy title="Business Type"
				:title-link-class="[bgTabsNavs, 'sub-tab px-3']"
			>
				<b-tabs
					active-tab-class="p-0 "
					pills
					nav-class="mb-0 mt-1"
					active-nav-item-class="bg-info box-shadow-info border-info info"
				>
					<b-tab lazy title="Active"
						:title-link-class="[bgTabsNavs, 'sub-tab px-3']"
					>
						<entrances-settings-active :isDeleted="0" :EntranceType="1" />
					</b-tab>
					<b-tab lazy title="Inactive"
						:title-link-class="[bgTabsNavs, 'sub-tab px-3']"
					>
						<entrances-settings-active :isDeleted="1" :EntranceType="1" />
					</b-tab>
				</b-tabs>
			</b-tab>

			<b-tab lazy title="Shipments"
				:title-link-class="[bgTabsNavs, 'sub-tab px-3']"
			>
				<b-tabs
					active-tab-class="p-0 "
					pills
					nav-class="mb-0 mt-1"
					active-nav-item-class="bg-info box-shadow-info border-info info"
				>
					<b-tab lazy title="Active"
					:title-link-class="[bgTabsNavs, 'sub-tab px-3']"
					>
						<entrances-settings-active :isDeleted="0" :EntranceType="2" />
					</b-tab>
					<b-tab lazy title="Inactive"
						:title-link-class="[bgTabsNavs, 'sub-tab px-3']"
					>
						<entrances-settings-active :isDeleted="1" :EntranceType="2" />
					</b-tab>
				</b-tabs>
			</b-tab>

			<b-tab lazy title="Add Charge"
			:title-link-class="[bgTabsNavs, 'sub-tab px-3']"
			>
				<b-tabs
					active-tab-class="p-0 "
					pills
					nav-class="mb-0 mt-1"
					active-nav-item-class="bg-info box-shadow-info border-info info"
				>
					<b-tab lazy title="Active"
					:title-link-class="[bgTabsNavs, 'sub-tab px-3']"
					>
						<entrances-charge :isDeleted="0" />
					</b-tab>
					<b-tab lazy title="Inactive"
						:title-link-class="[bgTabsNavs, 'sub-tab px-3']"
					>
						<entrances-charge :isDeleted="1" />
					</b-tab>
				</b-tabs>
			</b-tab>

			<b-tab lazy title="Tax Status"
			:title-link-class="[bgTabsNavs, 'sub-tab px-3']"
			>
				<b-tabs
					active-tab-class="p-0 "
					pills
					nav-class="mb-0 mt-1"
					active-nav-item-class="bg-info box-shadow-info border-info info"
				>
					<b-tab lazy title="Active"
						:title-link-class="[bgTabsNavs, 'sub-tab px-3']"
					>
						<entrances-settings-active :isDeleted="0" :EntranceType="4" />
					</b-tab>
					<b-tab lazy title="Inactive"
						:title-link-class="[bgTabsNavs, 'sub-tab px-3']"
					>
						<entrances-settings-active :isDeleted="1" :EntranceType="4" />
					</b-tab>
				</b-tabs>
			</b-tab>
		</b-tabs>
		-->
	</div>
</template>

<script>
	import EntrancesSettingsActive from "./EntrancesSettingsActive";
	//import EntrancesCharge from "./EntrancesCharge";
	export default {
		components: {
			EntrancesSettingsActive,
			//EntrancesCharge,
		},
		data() {
			return {
				tabs: [
					{
						route: "bussiness_type",
						title: "Business Type"
					},
					{
						route: "shipments_url",
						title: "Shipments",
					},
					{
						route: "add_charge",
						title: "Add Charge"
					},
					{
						route: "tax_status",
						title: "Tax Status"
					}
				],
			}
		},
 		created() {
			
		}
	}


</script>
